import React, { useState, useEffect } from 'react';

import API from '../Utilities/API';

import { Spinner, Collapse, HTMLSelect } from '@blueprintjs/core'

import NewNote from '../Notes/NewNote'
import SingleNote from './Single'

export default ({ case_id, setNumber, onLoad, doReload }) => {

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [lastChange, setLastChange] = useState(new Date().getTime());
    const [sortBy, setSortBy] = useState('note_date');

    useEffect(() => {
        if (!loaded) {
            API.ContentNotes(case_id).then((response) => {
                setData(response.data.Data);
                setLoaded(true);
                setNumber && setNumber(response.data.Data.length);
                onLoad && onLoad(response.data.Data);
            });
        }

        window.createCaseNote = function (text) {
            const title = text.split("\n")[0].trim();
            setEdit({
                title: title,
                text: text.replace(title, '').trim()
            });
            setLastChange(new Date().getTime());
        }
    }, [loaded])

    useEffect(() => {

        const newData = [...data];

        newData.sort((a, b) => {
            const a_str = sortBy === 'note_date' ? a.datetime : (a.news && a.news.length > 0 ? a.news[0].timestamp.replace(/T/, ' ') : a.datetime);
            const b_str = sortBy === 'note_date' ? b.datetime : (b.news && b.news.length > 0 ? b.news[0].timestamp.replace(/T/, ' ') : b.datetime);

            if (a_str < b_str) return 1;
            else if (a_str > b_str) return -1;
            else return 0;

        });

        setData([...newData]);

    }, [sortBy]);

    return !loaded ? <Spinner /> : (
        <>

            <NewNote
                key={lastChange}
                show={edit !== false}
                title={edit !== false ? edit.title : ''}
                text={edit !== false ? edit.text : ''}
                onSend={(title, text) => {
                    API.SaveContentNote({
                        content_id: case_id,
                        title: title,
                        text: text,
                        note_id: edit == false ? null : edit.id
                    }).then(() => {
                        setEdit(false);
                        setLoaded(false);
                    })
                }}
                rightHtml={(
                    <HTMLSelect
                        style={{
                            backgroundColor: '#394b59',
                            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
                            boxShadow: '0 0 0 1px rgba(16,22,26,.4)'
                        }}
                        onChange={(e) => {
                            setSortBy(e.target.value);
                        }}
                    >
                        <option value="note_date" select={sortBy === 'note_date'}>Order by note</option>
                        <option value="source_date" select={sortBy === 'source_date'}>Order by source</option>
                    </HTMLSelect>
                )}
            />
            {data.map((note) => (<>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <SingleNote
                        note={note}
                        key={`SingleNote-${note.id}`}
                        onDelete={() => {
                            if (window.confirm('Delete note?')) {
                                API.DeleteContentNote(note.id).then(() => {
                                    setLoaded(false);
                                });
                            }
                        }}
                        onPublish={() => {
                            doReload && doReload();
                            setLoaded(false);
                        }}
                        onCreateAssignment={() => {
                            setLoaded(false);
                        }}
                        afterEdit={() => {
                            doReload && doReload();
                            setLoaded(false);
                        }}
                    />
                </div>
            </>))}
        </>
    )

}