import React, { useState, useEffect } from 'react';

import { Button, Collapse, Classes, InputGroup, TextArea, ControlGroup } from '@blueprintjs/core';
import Summary from '../Utilities/Summary';

import LanguageSelect from '../Language/LanguageSelect'

export default (props) => {
    const [show, setShow] = useState(props.show || false);

    const [newTitle, setNewTitle] = useState(props.title || '');
    const [newText, setNewText] = useState(props.text || '');
    const [language, setLanguage] = useState(props.language || null);

    return (
        <>
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
            }}>

                {!props.hideNew && (
                    <div style={{
                        flexGrow: 1
                    }}>
                        <Button icon={'add'} onClick={() => { setShow(!show) }} text={'New note'} />
                    </div>
                )}

                {props.rightHtml && (
                    <div style={{
                        flexGrow: 1,
                        textAlign: 'right'
                    }}>{props.rightHtml}</div>
                )}
            </div>

            {props.summary ? (
                <div style={{ clear: 'both' }}>
                    <Summary
                        summaryData={props.summary}
                        auto={false}
                        visible={!!props.summary}
                    />
                </div>
            ) : null}
            <Collapse isOpen={show}>
                <div style={{ marginTop: 20 }}>
                    <p>
                        <ControlGroup fill={true}>
                            <InputGroup
                                value={newTitle}
                                onChange={(v) => {
                                    setNewTitle(v.target.value)
                                }}
                                placeholder={'Note title'}
                                style={{
                                    marginBottom: 10
                                }}
                            />
                            <LanguageSelect
                                style={{ maxWidth: 300, maxHeight: 30 }}
                                language={language}
                                onChange={(language) => {
                                    setLanguage(language);
                                }}
                            />
                        </ControlGroup>
                    </p>
                    <p>
                        <TextArea
                            large
                            value={newText}
                            onChange={(v) => {
                                setNewText(v.target.value)
                            }}
                            placeholder={'Note text'}
                            fill
                            style={{
                                height: 300
                            }}
                        />
                    </p>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            onClick={() => {
                                setShow(false);
                                setNewTitle('');
                                setNewText('');
                                setLanguage(null);
                                props.onClose && props.onClose();
                            }}
                            text={'Close'}
                        />
                        <Button
                            onClick={() => {
                                if (props.onSend) props.onSend(newTitle, newText, language);
                                setNewTitle('');
                                setNewText('');
                                setLanguage(null);
                                setShow(false);
                            }}
                            intent={'primary'}
                            icon={'add'}
                            text={'Save'}
                        />
                    </div>
                </div>

            </Collapse>
        </>
    )

}