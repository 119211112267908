import React, { Component } from 'react';

import API from '../Utilities/API';

import ResearchBar from '../Research/Bar';
import Cases from '../Cases/Cases';

import { Spinner, Drawer, Position, DrawerSize, Classes } from '@blueprintjs/core';

import OneArticle from './OneArticle';

class AllArticles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loaded: false,
            selected: [],
            showDrawer: false,
            drawerCase: null
        }

        this.loadData = this.loadData.bind(this);
        this.unSelectAll = this.unSelectAll.bind(this);
        this.selectAll = this.selectAll.bind(this);
    }
    componentDidMount() {
        if (!this.state.loaded) this.loadData();
    }

    loadData() {
        API.getAllArticles(this.props.group_id).then((response) => {
            let sel = [];
            sel = response.data.Data.filter((a) => (a.case && a.case.id)).map((a) => (a.id));
            if (sel.length == 0) sel = response.data.Data.map((a) => (a.id));
            this.setState({
                loaded: true,
                data: response.data.Data,
                selected: sel,
                originally_selected: sel
            }, () => {
                if (response.data.Data.length > 0) this.props.groupLoaded();
                if (this.state.drawerCase) {
                    if (window.confirm('Show case?')) {
                        this.setState({
                            showDrawer: true
                        })
                    }
                }
            })
        })
    }

    setSelected(article_id, status) {
        let sel = this.state.selected;
        if (status) sel.push(article_id);
        else sel = sel.filter((x) => (x != article_id));

        this.setState({
            selected: sel
        })
    }

    unSelectAll() {
        this.setState({
            loaded: false,
            selected: []
        }, () => {
            this.setState({
                loaded: true
            })
        })
    }

    selectAll() {
        this.setState({
            loaded: false,
            selected: this.state.originally_selected
        }, () => {
            this.setState({
                loaded: true
            })
        })
    }

    render() {
        let fwc = this.state.data.filter((a) => (a.case && a.case.id));
        let cases = new Array();
        if (fwc.length) {
            fwc.forEach((f) => { cases = cases.concat(f.cases) });
            fwc = fwc[0].case;
        }
        else fwc = null;

        return this.state.loaded ? (
            <>
                {this.state.selected.length > -1 ? (
                    <>
                        <ResearchBar
                            selectedCase={fwc}
                            selectedCases={cases}
                            articles={this.state.selected}
                            unSelectAll={this.unSelectAll}
                            selectAll={this.selectAll}
                            fullList={this.state.data}
                            group_id={this.props.group_id}
                            removeFromCase={(article_id, case_id) => {
                                if (window.confirm('Remove from case?')) {
                                    API.RemoveFromCase(article_id, case_id).then(() => {
                                        delete window.nd.cases[article_id];
                                        this.setState({
                                            loaded: false
                                        }, () => {
                                            this.loadData();
                                        })
                                    })
                                }
                            }}
                            onChangeCase={(casedata) => {
                                this.setState({
                                    loaded: false,
                                    drawerCase: casedata
                                }, () => {
                                    this.loadData();
                                })
                            }}
                            expandID={this.props.group_id}
                        />
                        {this.state.showDrawer && (
                            <Drawer
                                icon={'box'}
                                title={this.state.drawerCase.title}
                                autoFocus
                                canEscapeKeyClose={false}
                                canOutsideClickClose={false}
                                enforceFocus
                                hasBackdrop
                                position={Position.RIGHT}
                                size={'100%'}
                                isOpen={true}
                                className={Classes.DARK}
                                onClose={() => {
                                    this.setState({
                                        showDrawer: false,
                                        drawerCase: null
                                    })
                                }}
                            >
                                <Cases
                                    popup
                                    case={this.state.drawerCase}
                                    key={this.state.drawerCase.id}
                                    setCaseText={(text) => {

                                    }}
                                    reloadCases={() => {

                                    }}
                                    onCloseCase={() => {
                                        this.setState({
                                            showDrawer: false,
                                            drawerCase: null
                                        })
                                    }}
                                />
                            </Drawer>
                        )}
                    </>
                ) : null}
                {this.state.data.map((article, i) => (
                    this.props.skip && this.props.skip === article.id ? null : (
                        <div style={{ marginBottom: 20 }} key={`AllArticles-${article.id}`}>
                            <OneArticle article={article} selected={this.state.selected.indexOf(article.id) > -1} setSelected={this.setSelected.bind(this)} />
                            <div style={{ clear: 'both', margin: 20 }}></div>
                        </div>
                    )
                ))}
            </>
        ) : <Spinner />;
    }
}

export default AllArticles;