import React from 'react';

import API from '../Utilities/API';
import U from '../Utilities/Utilities';

import { Button, Spinner, PanelStack2 } from '@blueprintjs/core'

import SingleCase from './SingleCase'

import { Link } from "react-router-dom";

class CasesList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loaded: false,
            activeCase: {
                id: ''
            }
        }

        this.renderList = this.renderList.bind(this);
        this.loadData = this.loadData.bind(this);
        this.renderDate = this.renderDate.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.loadData();
        }
    }

    loadData() {
        API.Cases(this.props.closed, this.props.filter, this.props.archive).then((r) => {
            this.setState({
                loaded: true,
                data: r.data && r.data.Data ? r.data.Data.filter((c) => (this.props.shared || (!this.props.shared && c.user_id == window.nd.User.id))) : []
            }, () => {
                if (this.props.noClick) return;
                let ac = window.location.pathname.split('/')[2];
                if (!ac) return;

                /*
                let seeClosed = (ac[0] === 'x');
                if (seeClosed != this.props.closed) {
                    this.props.setClosed(seeClosed);
                    return;
                }
                */

                if (!this.activeCase || !this.activeCase.id || this.activeCase.id != ac) {
                    let x = r.data.Data.filter((c) => (ac.indexOf(c.id) > -1));
                    if (x.length > 0) {
                        this.setState({
                            activeCase: x[0]
                        }, () => {
                            this.props.setActiveCase(x[0]);
                        })
                    }
                }
            })
        })
    }

    setActiveCase(c) {
        this.setState({
            activeCase: c
        }, () => {
            this.props.setActiveCase(c);
        })
    }

    renderDate(date) {
        return (

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'space-between',
                width: '100%'
            }}>
                <div style={{
                    width: '30%'
                }}></div>
                <div style={{
                    width: '40%'
                }}>
                    <Button
                        fill
                        minimal
                        disabled
                        large
                        icon={'calendar'}
                        style={{ color: '#FFB366', cursor: 'default', position: 'sticky', top: 0, backgroundColor: '#30404d', zIndex: 1 }}
                    >{date}</Button>
                </div>
                <div style={{
                    width: '30%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 'auto',
                    }}>
                        <div style={{
                            width: '40%'
                        }}>
                            <Button
                                fill
                                minimal
                                disabled
                                large
                                alignText='left'
                                style={{ color: '#FFB366', cursor: 'default', position: 'sticky', top: 0, backgroundColor: '#30404d', zIndex: 1 }}>Category</Button>
                        </div>
                        <div style={{
                            width: '40%'
                        }}>
                            <Button
                                fill
                                minimal
                                disabled
                                large
                                alignText='left'
                                style={{ color: '#FFB366', cursor: 'default', position: 'sticky', top: 0, backgroundColor: '#30404d', zIndex: 1 }}>Type</Button>
                        </div>
                        <div style={{
                            width: '20%'
                        }}>
                            <Button
                                fill
                                minimal
                                disabled
                                large
                                alignText='left'
                                style={{ color: '#FFB366', cursor: 'default', position: 'sticky', top: 0, backgroundColor: '#30404d', zIndex: 1 }}>Notes</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderList() {
        if (this.props.routeCase && this.props.routeCase.length > 0 && this.props.routeCase.substring(1) != this.state.activeCase.id) {
            const cx = this.state.data.filter((cx) => {
                return cx.id === this.props.routeCase || cx.id === this.props.routeCase.substring(1);
            });
            if (cx.length > 0) {
                if (!this.props.noClick) this.setActiveCase(cx[0]);
                return null;
            }
        }

        let date = '';

        return <div style={{ display: 'block', width: '100%', height: '100%', overflow: 'auto' }}>{this.state.data.filter((c) => (!this.props.my || (c.user_id && c.user_id == window.nd.User.id))).map((c) => {

            let time = c.insert_datetime;
            if (c.last_update) time = c.last_update;
            time = U.date(time);
            let showDate = false;
            if (time != date) {
                date = time;
                showDate = true;
            }
            let A = c.active === '1' ? 'a' : 'x';
            return (
                <>
                    {
                        showDate ? this.renderDate(date) : null
                    }
                    <Link to={this.props.noClick ? window.location.pathname : `/cases/${c.id}`} style={{ textDecoration: 'none' }}>
                        <SingleCase
                            case={c}
                            expanded={this.props.isExpanded}
                            active={this.props.activeCase && this.props.activeCase.id == c.id ? true : undefined}
                            setActiveCase={this.setActiveCase.bind(this, c)}
                        />
                    </Link>
                </>
            )
        })}</div>
    }

    render() {
        return !this.state.loaded ? <Spinner /> : (
            <PanelStack2
                stack={[
                    {
                        title: this.props.hideTopBar ? null : <div style={{ width: '100%' }}>
                            <Link to={'/cases'}>
                                <Button
                                    minimal
                                    icon={this.state.expanded ? 'minimize' : 'maximize'} style={{ position: 'absolute', top: 0, right: 0 }}
                                    onClick={() => {
                                        this.props.toggleExpanded();
                                    }}
                                />
                            </Link>
                        </div>,
                        renderPanel: this.renderList
                    }
                ]}
                className={'full-height' + (this.props.isExpanded ? ' list-expanded' : ' list-not-expanded')}
            />
        )
    }
}

export default CasesList;