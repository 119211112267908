import React from 'react';

import API from '../Utilities/API';
import Send from '../Popup/Send';

import { Alignment, Button, Navbar, NavbarGroup } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select';

import CaseSelect from '../Cases/SelectAdd';

import ExpanderButton from '../Expander/Button';

class Bar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCase: this.props.selectedCase && this.props.selectedCase.id ? this.props.selectedCase : {
                title: 'Select case'
            },
            showExpand: false
        }

    }

    render() {
        return (
            <>
                <Navbar className={'scrollX1300'} style={{ textAlign: 'right', position: 'sticky', top: 0 }}>
                    <NavbarGroup>
                        {this.props.selectAll && this.props.unSelectAll ? (
                            <Button
                                active={this.props.articles.length > 0 ? true : undefined}
                                icon={this.props.articles.length > 0 ? 'circle' : 'tick-circle'}
                                onClick={this.props.articles.length > 0 ? this.props.unSelectAll : this.props.selectAll}
                            >{this.props.articles.length > 0 ? 'Deselect All' : 'Select All'}</Button>
                        ) : null}
                        {this.props.expandID && (
                            <ExpanderButton id={this.props.expandID} />
                        )}
                        {this.props.hideSend ? null : (
                            <Send
                                articles={this.props.articles}
                                fullList={this.props.fullList}
                            />
                        )}
                    </NavbarGroup>
                    <NavbarGroup align={Alignment.RIGHT}>
                        <CaseSelect
                            selectedCase={this.state.selectedCase}
                            selectedCases={this.props.selectedCases}
                            articles={this.props.articles}
                            group_id={this.props.group_id}
                            removeFromCase={this.props.removeFromCase}
                            onSelect={this.props.onChangeCase}
                        />
                    </NavbarGroup>
                </Navbar>
            </>
        )
    }
}

export default Bar;