import React, { useState } from 'react';

import { ControlGroup, Button, InputGroup } from '@blueprintjs/core'

export default (props) => {

    const [searchText, setSearchText] = useState('');
    const [hasSearched, setHasSearched] = useState(false);

    const search = () => {
        if (searchText.length > 0 && props.onSearch) {
            props.onSearch(searchText);
            setHasSearched(true);
        }
    }

    return (
        <>
            <ControlGroup style={props.style}>
                <input class="bp5-input bp5-fill" type="text" placeholder={"Search"} dir="auto" onChange={(e) => {
                    setSearchText(e.target.value);
                    props.onChange && props.onChange(e.target.value);
                }} onKeyUp={(e) => {
                    if (e.key === 'Enter') search();
                }} value={searchText} />
                {hasSearched ? (
                    <Button intent={'danger'} icon={'cross'} onClick={() => {
                        if (window.confirm('Clear search?')) {
                            setHasSearched(false);
                            setSearchText('');
                            if (props.onSearch) props.onSearch('');
                        }
                    }} />
                ) : null}
                <Button icon={'search'} text={props.buttonText || 'Search'} onClick={search} />
            </ControlGroup>
        </>
    )

}