import React from 'react';

import Linkify from 'react-linkify';

import U from '../Utilities/Utilities';

import { Text, Tag, Button, Dialog, Classes } from '@blueprintjs/core';

import extractDomain from "extract-domain";
import Summary from '../Utilities/Summary';

class OneArticle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            imageError: false,
            selected: this.props.selected || this.props.hideSelect,
            showSummary: this.props.article && this.props.article.summary && this.props.article.summary.text && this.props.article.summary.text.length > 0,
            showExpand: true
        }

        this.errored = this.errored.bind(this);
    }

    errored() {
        this.setState({
            imageError: true
        })
    }

    render() {
        let article = this.props.article;

        return (
            <>
                <div style={{ padding: 10, clear: 'both', marginTop: 20, opacity: (this.state.selected ? 1 : 0.3) }} key={`singlearticle-${article.id}`}>
                    <div style={{
                        width: '100%',
                        height: 50
                    }}>
                        {this.props.hideSelect ? null : (
                            <div style={{ width: 40, paddingTop: 10, float: 'left' }}>
                                <Button
                                    active={this.state.selected ? true : undefined}
                                    onClick={() => {
                                        this.setState({
                                            selected: !this.state.selected
                                        }, () => {
                                            this.props.setSelected(article.id, this.state.selected)
                                        })
                                    }}
                                    style={{ width: 30 }}
                                    icon={this.state.selected ? 'tick' : null}
                                ></Button>
                            </div>
                        )}
                        <div style={{ width: 'calc(100% - 40px)', float: 'left' }}>
                            <h1 style={{ marginTop: 0, marginBottom: 0 }} className={'translate'} dangerouslySetInnerHTML={{ __html: U.prettyStamp(article.timestamp) + ' &bull; ' + article.title }}></h1>
                            <Button icon={'bookmark'} style={{ float: 'right' }} onClick={() => {
                                this.setState({
                                    showSummary: true
                                })
                            }} />
                        </div>
                    </div>
                    <a style={{ marginBottom: 10, display: 'block' }} href={article.original_link} target={'_blank'}>{window.decodeURIComponent(article.original_link)}</a>
                    {this.state.showSummary ? (
                        <div>
                            <Summary
                                id={article.id}
                                summaryData={article.summary}
                                auto={!(this.props.article && this.props.article.summary && this.props.article.summary.text && this.props.article.summary.text.length > 0)}
                            />
                        </div>
                    ) : null}
                    <div style={{ width: '100%' }}>
                        {!this.state.imageError && article.image && article.image.length ? (
                            <div className={'small-image'} style={{ backgroundImage: `url('${article.image}')`, marginRight: 10 }}>
                                <Tag fill interactive minimal style={{ marginTop: 5 }}>{extractDomain(article.image)}</Tag>
                                <img src={article.image} style={{ visibility: 'hidden', position: 'absolute', top: -10000, left: -10000 }} onError={this.errored} />
                            </div>
                        ) : null}
                        <div style={{ width: 'calc(100% - 170px)', float: 'left', marginRight: 0, direction: article.languageData && article.languageData.rtl == '1' ? 'rtl' : 'ltr' }}>
                            <Text style={{ whiteSpace: 'pre-wrap' }}>
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="blank" href={decoratedHref} key={key}>
                                        {decodeURI(decoratedText)}
                                    </a>)}>
                                    {<span className={'translate'} dangerouslySetInnerHTML={{ __html: article.text?.trim() || '' }}></span>}
                                </Linkify>
                            </Text>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default OneArticle;