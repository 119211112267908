import React, { useState, useEffect } from 'react';

import { Card, Button, ButtonGroup, Collapse, AnchorButton, Divider } from '@blueprintjs/core'

import U from '../Utilities/Utilities'

import PublishButton from '../Publish/Button'
import AssignmentButton from '../Assignment/Button'

import NewNote from '../Notes/NewNote'

import API from '../Utilities/API';
import Summary from '../Utilities/Summary';

import IntelligenceKeywords from '../Intelligence/Keywords';

export default ({ note, onEdit = false, onPublish = null, onCreateAssignment = null, onDelete = null, afterEdit = null }) => {

    const [expand, setExpand] = useState(false)
    const [edit, setEdit] = useState(false);

    const [noteData, setNoteData] = useState(note);

    const [showSummary, setShowSummary] = useState(note.summary && note.summary.text && note.summary.text.length);
    const [autoSummary, setAutoSummary] = useState(false);

    return edit ? (
        <>
            <NewNote
                show
                title={noteData.title}
                text={noteData.text}
                language={noteData.languageData}
                hideNew
                onClose={() => {
                    setEdit(false);
                }}
                summary={noteData.summary}
                onSend={(title, text, language) => {
                    API.SaveContentNote({
                        content_id: note.case_id,
                        title: title,
                        text: text,
                        language: language.shortcode,
                        note_id: note.id
                    }).then(() => {
                        setEdit(false);
                        setNoteData({
                            ...note,
                            title: title,
                            text: text,
                            languageData: language
                        });
                        afterEdit && afterEdit();
                    })
                }}
            />
            <div style={{ clear: 'both', width: '100%', marginTop: 10 }}>
                <Button
                    minimal={!expand}
                    fill
                    icon={expand ? 'chevron-up' : 'chevron-down'}
                    onClick={() => setExpand(!expand)} text={`Connected news: ${note.news.length}`}
                />
                <Collapse isOpen={expand}>
                    {note.news.map((news) => (
                        <div style={{ marginTop: 10, marginBottom: 10, borderBottom: '1px solid white', padding: 10 }} onClick={() => {
                            window.document.getElementById(`SingleSource-${news.id}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                        }}>
                            <div style={{ fontWeight: 'bold' }}>{U.prettyStampFull(news.timestamp)} - {news.title}</div>
                            <a rel="noreferrer noopener" href={news.original_link} target={'_blank'}>{news.original_link}</a>
                            <pre style={{ whiteSpace: 'pre-line' }}>{news.intro}</pre>
                        </div>
                    ))}
                </Collapse>


            </div>
        </>
    ) : (
        <>
            <Card interactive>
                <div style={{ height: 20 }} className={'bp5-text-muted bp5-text-small'}>
                    <div style={{ float: 'left', textAlign: 'left', width: '50%' }}>
                        {note.user_name} {noteData.languageData && noteData.languageData.shortcode ? ` / ${noteData.languageData.name}` : null}
                    </div>
                    <div style={{ float: 'left', textAlign: 'right', width: '50%', whiteSpace: 'pre-wrap', lineHeight: '125%' }}>
                        Note: {U.prettyStampFull(note.datetime)}
                        {note.news && note.news.length > 0 && note.news[0].timestamp ? `\nSource: ${U.prettyStampFull(note.news[0].timestamp.replace(/T/, ' '))}` : null}
                    </div>
                </div>
                {showSummary ? (
                    <div style={{ clear: 'both' }}>
                        <Summary
                            id={null}
                            request={note}
                            summaryData={note.summary}
                            auto={autoSummary}
                            visible={autoSummary}
                            onDone={(data) => {
                                setNoteData({
                                    ...noteData,
                                    summary: data
                                });
                            }}
                        />
                    </div>
                ) : null}
                <h5>{noteData.title}</h5>
                <p>
                    {note.fileData && note.fileData.id && (
                        <img src={note.fileData.location} align={'left'} style={{ width: 200, marginRight: 10 }} />
                    )}
                    {noteData.text}
                </p>
                <IntelligenceKeywords text={`${noteData.title}. ${noteData.text}`} text_id={noteData.id} />
                <div style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <div style={{ whiteSpace: 'nowrap', paddingTop: 11 }}>
                        {noteData.publish && noteData.publish.length > 0 && noteData.publish_response && noteData.publish_response.data ? (
                            <>
                                <span style={{ color: '#48aff0' }} title={`${U.prettyStampFull(note.publish)} ${note.publish_response.data && note.publish_response.data.attributes && note.publish_response.data.attributes.datetime && `con data ${U.prettyStampFull(note.publish_response.data.attributes.datetime)}`}`}>
                                    Pubblicata
                                    <Button small icon={'remove'} minimal onClick={() => {
                                        if (window.confirm('Unpublish note?')) {
                                            API.UnpublishContentNote({
                                                note_id: note.id,
                                                case_id: note.content_id
                                            }).then(() => {
                                                setNoteData({
                                                    ...noteData,
                                                    publish: null,
                                                    publish_response: null
                                                });
                                            });
                                        }
                                    }} />
                                </span><br />
                            </>
                        ) : null}
                        {note.assignment && note.assignment.length > 0 ? (
                            <>
                                <span style={{ color: 'rgb(114, 202, 155)' }} title={U.prettyStampFull(note.assignment)}>
                                    Assignment
                                </span><br />
                            </>
                        ) : null}
                    </div>
                    <div style={{ textAlign: 'right', width: '100%' }}>
                        <ButtonGroup minimal>
                            <Button icon={'bookmark'} text={'AI'} onClick={() => {
                                setAutoSummary(true);
                                setShowSummary(true);
                            }} />
                            {onEdit ? (
                                <Button intent={'warning'} icon={'edit'} onClick={onEdit} />
                            ) : <Button intent={'warning'} icon={'edit'} onClick={() => {
                                setEdit(true);
                                setExpand(true);
                            }} />}
                            {onDelete ? (
                                <Button intent={'danger'} icon={'trash'} onClick={onDelete} />
                            ) : null}
                            {note.publish_response && note.publish_response.data && note.publish_response.data.attributes && note.publish_response.data.attributes.slug ? (
                                <AnchorButton intent={'primary'} href={`https://web.cesno.org:2000/article/${note.publish_response.data.attributes.slug}`} target={'_blank'} rel="noreferrer noopener" icon={'eye-open'} />
                            ) : (
                                <AnchorButton intent={'primary'} href={`https://web.cesno.org:2000/article_preview/${note.id}`} target={'_blank'} rel="noreferrer noopener" icon={'eye-open'} />
                            )}
                            {window.nd.User && window.nd.User.permissions && window.nd.User.permissions.case_note_publish && (
                                <PublishButton
                                    date={note.publish ? new Date(note.publish_response?.data?.attributes?.datetime || note.publish) : new Date()}
                                    free={note.publish ? (note.publish_response?.data?.attributes?.free ?? true) : true}
                                    onPublish={onPublish}
                                    data={{
                                        type: 'CaseNote',
                                        ...note
                                    }}
                                />
                            )}
                            <AssignmentButton onCreateAssignment={onCreateAssignment} data={{
                                type: 'ContentNote',
                                ...note
                            }} />
                        </ButtonGroup>
                    </div>
                </div>
                <div style={{ clear: 'both', width: '100%', marginTop: 10 }}>
                    <Button
                        minimal={!expand}
                        fill
                        icon={expand ? 'chevron-up' : 'chevron-down'}
                        onClick={() => setExpand(!expand)} text={`Connected news: ${note.news.length}`}
                    />
                    <Collapse isOpen={expand}>
                        {note.news.map((news) => (
                            <div style={{ marginTop: 10, marginBottom: 10, borderBottom: '1px solid white', padding: 10 }} onClick={() => {
                                window.document.getElementById(`SingleSource-${news.id}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                            }}>
                                <div style={{ fontWeight: 'bold' }}>{U.prettyStampFull(news.timestamp)} - {news.title}</div>
                                <a rel="noreferrer noopener" href={news.original_link} target={'_blank'}>{news.original_link}</a>
                                <pre style={{ whiteSpace: 'pre-line' }}>{news.intro}</pre>
                                <IntelligenceKeywords article_id={news.id} />
                            </div>
                        ))}
                    </Collapse>


                </div>

            </Card>
        </>
    )

}