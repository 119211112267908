import React, { useState, useEffect } from 'react';
import { Select, MultiSelect } from "@blueprintjs/select";
import { MenuItem, Button } from '@blueprintjs/core'

import API from '../Utilities/API';

export default (props) => {

    const TypeSelect = Select.ofType();
    const CountrySelect = Select.ofType();
    const LanguageSelect = Select.ofType();
    const CategorySelect = Select.ofType();


    const [type, setType] = useState(props.case_type && props.case_type.id ? props.case_type : {
        title: 'Select case type'
    });
    const [country, setCountry] = useState(props.country && props.country.name ? props.country : {
        name: 'Select country'
    });
    const [language, setLanguage] = useState(props.language && props.language.name ? props.language : {
        name: 'Select language'
    });
    const [category, setCategory] = useState(props.category && props.category.id ? props.category : {
        name: 'Select category'
    });

    const [types, setTypes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        API.getFilters().then((response) => {
            if (response && response.data && response.data.Data) {
                setTypes([
                    {
                        title: 'Select case type'
                    },
                    ...response.data.Data.case_types,
                ]);
                setCountries([
                    {
                        name: 'Select country'
                    },
                    ...response.data.Data.countries,
                ]);
                setLanguages([
                    {
                        name: 'Select language'
                    },
                    ...response.data.Data.language
                ]);
                setCategories([
                    {
                        name: 'Select category'
                    },
                    ...response.data.Data.categories
                ]);
            }
        })
    }, [])

    const filter = (query, data, i, exact) => {
        let title = '';
        if (data.name) title = data.name.toLowerCase();
        if (data.title) title = data.title.toLowerCase();
        query = query.toLowerCase();
        if (exact) return title === query;
        else return title.length == 0 || title.indexOf(query) > -1;
    }

    return (
        <>
            <div style={{ display: 'flex', width: '100%', flexDirection: (props.vertical ? 'column' : 'row') }}>
                {!props.hideType && (
                    <div style={{ display: 'flex', flexGrow: 1, paddingBottom: (props.vertical ? 10 : 0), paddingRight: (props.vertical ? 0 : 10) }}>
                        <TypeSelect
                            fill
                            items={types}
                            itemPredicate={filter}
                            itemRenderer={(c, { handleClick }) => {
                                return <MenuItem
                                    text={<div dangerouslySetInnerHTML={{ __html: c.title }}></div>}
                                    onClick={handleClick}
                                />
                            }}
                            onItemSelect={(id) => {
                                setType(id);
                                if (props.typeSelect) props.typeSelect(id);
                            }}
                            menuProps={{ className: 'limit-popup' }}
                        >
                            <Button fill text={type.title.trim()} rightIcon="double-caret-vertical" />
                        </TypeSelect>
                    </div>
                )}
                <div style={{ display: 'flex', maxHeight: 500, overflow: 'auto', flexGrow: 1, paddingBottom: (props.vertical ? 10 : 0), paddingRight: (props.vertical ? 0 : 10) }}>
                    <CountrySelect
                        fill
                        items={countries}
                        itemPredicate={filter}
                        itemRenderer={(c, { handleClick }) => {
                            return <MenuItem
                                text={<div dangerouslySetInnerHTML={{ __html: c.name }}></div>}
                                onClick={handleClick}
                            />
                        }}
                        onItemSelect={(id) => {
                            setCountry(id);
                            if (props.countrySelect) props.countrySelect(id);
                        }}
                        menuProps={{ className: 'limit-popup' }}
                    >
                        <Button fill text={country.name.replace(/\&nbsp;/g, '').trim()} rightIcon="double-caret-vertical" />
                    </CountrySelect>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                    <LanguageSelect
                        fill
                        items={languages}
                        itemPredicate={filter}
                        itemRenderer={(l, { handleClick }) => {
                            return <MenuItem
                                text={l.name}
                                onClick={handleClick}
                            />
                        }}
                        onItemSelect={(id) => {
                            setLanguage(id);
                            if (props.languageSelect) props.languageSelect(id);
                        }}
                        menuProps={{ className: 'limit-popup' }}
                    >
                        <Button fill text={language.name} rightIcon="double-caret-vertical" />
                    </LanguageSelect>
                </div>
                {!props.hideCategory && (
                    <div style={{ display: 'flex', flexGrow: 1, paddingBottom: (props.vertical ? 10 : 0), paddingRight: (props.vertical ? 0 : 10) }}>
                        <CategorySelect
                            fill
                            items={categories}
                            itemPredicate={filter}
                            itemRenderer={(l, { handleClick }) => {
                                return <MenuItem
                                    text={l.name}
                                    onClick={handleClick}
                                />
                            }}
                            onItemSelect={(id) => {
                                setCategory(id);
                                if (props.categorySelect) props.categorySelect(id);
                            }}
                            menuProps={{ className: 'limit-popup' }}
                        >
                            <Button fill text={category.name} rightIcon="double-caret-vertical" />
                        </CategorySelect>
                    </div>
                )}
            </div >
        </>
    )

}