import React, { useState, useEffect } from 'react';
import { Navbar, NavbarGroup, NavbarHeading, NavbarDivider, Spinner, Button, Alignment } from '@blueprintjs/core';
import Single from './Article'
import API from '../Utilities/API'
import OneArticle from './OneArticle'
import U from '../Utilities/Utilities'
import { Drawer, Position, Classes } from '@blueprintjs/core';
import Cases from '../Cases/Cases';
import CaseSelect from '../Cases/SelectAdd'

import Filter from '../Filter/Filter';
import EditSearch from './EditSearch'

export default (props) => {

    const newTimeout = 20; //Seconds to check for new news

    const [search, setSearch] = useState(false);
    const [news, setNews] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [newCount, setNewCount] = useState(0);
    const [latest, setLatest] = useState('');
    const [loading, setLoading] = useState(false);
    const [moreLoad, setMoreLoad] = useState(false);

    const [pages, setPages] = useState(0);
    const limit = 50;

    const [activeArticle, setActiveArticle] = useState(false)

    const [range, setRange] = useState([null, null]);

    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerCase, setDrawerCase] = useState(null);

    const [selected, setSelected] = useState([]);
    const [selectedUpdate, setSelectedUpdate] = useState(1);

    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState(null);
    const [filterLoaded, setFilterLoaded] = useState(0);

    const [searching, setSearching] = useState(false);
    const [edit, setEdit] = useState(false);

    const onCheck = (group, state) => {
        let newSelected = selected;
        if (state === true) {
            newSelected.push(group);
        } else {
            newSelected = selected.filter((s) => (s.group && s.group.id != group.group.id));
        }
        setSelected(newSelected);
        setSelectedUpdate(new Date().getTime());
    }

    const checkChecked = (group) => {
        return selected.filter((s) => (s.group.id === group.group.id)).length > 0;
    }

    let checkInterval = 0;

    const splitLocation = window.location.href.split('/');
    const last = splitLocation.pop();
    const page = splitLocation.pop();

    let id = props.searchID || '';
    if (id.length === 0 && page === 'intelligence') id = last;

    useEffect(() => {
        clearInterval(checkInterval);
        setNews([]);
        setLoaded(false);
        setPages(0);
        if (id != 'intelligence' && id.length == 20) {
            API.SavedSearch(id).then((response) => {
                setSearch(response.data.Data.search);
                if (query === '') setQuery(response.data.Data.search.query);
                if (response.data.Data.parsed) {
                    if (!filter) setFilter(response.data.Data.parsed);
                    setFilterLoaded(filterLoaded + 1);
                }
                API.Intelligence(id, response.data.Data.search.query, null, range, pages * limit).then((r) => {
                    setNews(r.data.Data && r.data.Data.hits ? r.data.Data.hits : []);
                    setLoaded(true);
                    if (r.data.Data && r.data.Data.hits) setLatest(r.data.Data.hits[0]._source.timestamp);
                })
            })
        } else {
            API.IntelligenceNews(query, pages * limit).then((r) => {
                if (r.data.Data.hits.length > 0) {
                    setNews(r.data.Data.hits);
                    setLatest(r.data.Data.hits[0]._source.timestamp);
                }
                setLoaded(true);
            })
        }
    }, [range, query]);

    useEffect(() => {
        if (news.length) {
            API.IntelligenceNews(query, pages * limit).then((r) => {
                if (r.data && r.data.Data && r.data.Data.hits) {
                    setNews([...news, ...r.data.Data.hits]);
                }
                setMoreLoad(false);
            })
        }
    }, [pages])

    const renderNews = () => {
        let date = '';
        return (
            <>
                {newCount > 0 ? (
                    <Button large fill loading={loading} style={{ background: '#1F4B99' }} onClick={() => {
                        setLoading(true);
                        API.IntelligenceNews().then((r) => {
                            let newList = [];
                            let newNews = r.data.Data.hits.forEach((hit) => {
                                if (hit._source.timestamp > latest) {
                                    newList.push({
                                        ...hit,
                                        isNew: true
                                    });
                                }
                            });
                            news.forEach((article) => {
                                newList.push({
                                    ...article,
                                    isNew: false
                                })
                            })
                            setNews(newList);
                            setLatest(newList[0]._source.timestamp);
                            setLoading(false);
                            setNewCount(0);
                        })
                    }}>
                        There are <strong>{newCount}</strong> new articles. Click here to show.
                    </Button>
                ) : null}
                {news.map((n) => {
                    let cDate = U.date(n._source.timestamp);
                    let showDate = false;
                    if (cDate != date) {
                        date = cDate;
                        showDate = true;
                    }
                    return (
                        <>
                            {showDate ? (<Button
                                fill
                                minimal
                                disabled
                                large
                                icon={'calendar'}
                                style={{ color: '#FFB366', cursor: 'default', position: 'sticky', top: 0, backgroundColor: '#30404d', zIndex: 1 }}
                            >{date}</Button>) : null}
                            <Single
                                {...props}
                                article={n}
                                onClick={() => {
                                    if (activeArticle._id === n._id) setActiveArticle(false);
                                    else setActiveArticle(n);
                                }}
                                active={activeArticle._source && activeArticle._id == n._id}
                                onCheck={onCheck}
                                checkChecked={checkChecked}
                            />
                        </>
                    );
                })}
                <div style={{ padding: 10 }}>
                    <Button large fill loading={moreLoad} onClick={() => {
                        setMoreLoad(true);
                        setPages(pages + 1);
                    }}>Load more</Button>
                </div>
            </>
        )
    }

    return (
        <>
            <Navbar style={{ position: 'sticky', top: 0, paddingLeft: 0 }}>
                <NavbarGroup>
                    <NavbarHeading>
                        Intelligence{search && search.title ? ` » ${search.title}` : null}
                    </NavbarHeading>
                    <>
                        <NavbarDivider />
                        <NavbarGroup>
                            <CaseSelect selectedCase={props.caseID} forceCase={props.forceCase} key={`CaseSelect-${selectedUpdate}`} articles={selected.map((g) => (g.group.id))} onSelect={(casedata) => {
                                selected.forEach((g) => {
                                    const id = g.group.id;
                                    if (window['reloadCaseTitle' + id]) window['reloadCaseTitle' + id]();
                                })
                                if (!props.caseID) {
                                    if (window.confirm('Open case?')) {
                                        setDrawerCase(casedata);
                                        setShowDrawer(true);
                                    }
                                } else {
                                    props.onSelect && props.onSelect();
                                }
                            }} />
                        </NavbarGroup>
                    </>
                </NavbarGroup>
            </Navbar>
            <Navbar fixedToTop>
                <NavbarGroup align={Alignment.CENTER} style={{ width: 1600, margin: 'auto' }}>
                    {edit && (!search.id || (search.user_id === window.nd.User.id)) && (
                        <EditSearch
                            search={search}
                            onClose={() => {
                                setEdit(false);
                            }}
                            key={`SaveSearch`}
                            onSave={(title, folder) => {
                                let q = query;
                                if (id != 'intelligence' && id.length == 20) q += '&search_id=' + id;

                                API.SaveIntelligenceSearch(q, title, folder).then((response) => {
                                    window.askUnload = false;
                                    if (response.data && response.data.Data && response.data.Data.id) {
                                        if (props.onSavedSearchCreate) {
                                            props.onSavedSearchCreate(response.data.Data);
                                            setEdit(false);
                                        }
                                        else {
                                            if (props.closeSavedSearch) {
                                                setEdit(false);
                                                if (window.confirm('Changes saved. Close Saved Search?')) {
                                                    props.closeSavedSearch();
                                                }
                                            }
                                            else if (window.top.location.href.indexOf('/cases/') != -1) {
                                                setEdit(false);
                                                alert('Changes saved');
                                            } else {
                                                window.top.location.href = `/intelligence/${response.data.Data.id}`;
                                            }
                                        }
                                    }
                                })
                            }}
                        />
                    )}
                    <Filter
                        initial={filter}
                        key={`Filter-${filterLoaded}`}
                        searching={searching}
                        search={search}
                        searchStyle={{
                            width: 1200,
                            maxWidth: 1200
                        }}
                        onSearch={(query) => {
                            setQuery(query);
                            setSearching(true);
                        }}
                        onSave={() => {
                            setEdit(true);
                        }}
                        setKeywords={(kws) => {
                            const f = { ...filter };
                            f.keyword = kws;
                            setFilter(f);
                        }}
                        setCountries={(countries) => {
                            const f = { ...filter };
                            f.country = countries;
                            setFilter(f);
                        }}
                        setLanguages={(languages) => {
                            const f = { ...filter };
                            f.language = languages;
                            setFilter(f);
                        }}
                        setKwType={(kwtype) => {
                            const f = { ...filter };
                            f.kwtype = kwtype;
                            setFilter(f);
                        }}
                    />
                </NavbarGroup>
            </Navbar>
            <div style={{ display: 'block', width: '100%', height: 'calc(100% - 100px)', overflow: 'hidden' }}>
                <div style={{
                    width: (activeArticle._source ? '30%' : '100%'),
                    height: '100%',
                    overflow: 'auto',
                    float: 'left'
                }}>
                    {loaded ? renderNews() : <div style={{ marginTop: 50 }}><Spinner /></div>}
                </div>
                {activeArticle._source ? (
                    <div style={{
                        width: '70%',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        <OneArticle
                            key={`Intelligence-${activeArticle._id}`}
                            article={activeArticle}
                        />
                    </div>
                ) : null}
            </div>
            {showDrawer && (
                <Drawer
                    icon={'box'}
                    title={drawerCase.title}
                    autoFocus
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    enforceFocus
                    hasBackdrop
                    position={Position.RIGHT}
                    size={'100%'}
                    isOpen={true}
                    className={Classes.DARK}
                    onClose={() => {
                        setDrawerCase(null);
                        setShowDrawer(false);
                    }}
                >
                    <Cases
                        popup
                        case={drawerCase}
                        key={drawerCase.id}
                        setCaseText={(text) => {

                        }}
                        reloadCases={() => {

                        }}
                        onCloseCase={() => {
                            setDrawerCase(null);
                            setShowDrawer(false);
                        }}
                    />
                </Drawer>
            )}
        </>
    )

}