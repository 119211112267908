import React from 'react';

import API from '../Utilities/API';
import { Alignment, Tabs, Tab, Navbar, Tag, Spinner } from '@blueprintjs/core';

class KeywordsGroups extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedGroup: 'all',
            data: [],
            loaded: false,
            selectedKeywords: this.props.selectedKeywords ? this.props.selectedKeywords.map((s) => (s.title)) : []
        }

        this.renderKeywords = this.renderKeywords.bind(this);
        this.setSelectedGroup = this.setSelectedGroup.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.loadData();
        }
    }

    loadData() {
        if (this.props.group.group) {
            API.getAllKeywords(this.props.group.group.id).then((response) => {
                if (response.data.Data && response.data.Data.length) {
                    this.setState({
                        loaded: true,
                        data: response.data.Data
                    }, () => {
                        //document.getElementById('SingleGroup' + this.props.group.group.id).scrollIntoView()
                        //document.getElementById('SingleGroup' + this.props.group.group.id).scrollTop += 200;
                    })
                } else {
                    API.getAllKeywords(this.props.group.group.id).then((response) => {
                        this.setState({
                            loaded: true,
                            data: response.data.Data
                        }, () => {
                            //document.getElementById('SingleGroup' + this.props.group.group.id).scrollIntoView()
                            //document.getElementById('SingleGroup' + this.props.group.group.id).scrollTop += 200;
                        })
                    })
                }
            })
        }
    }

    renderKeywords() {
        if (this.state.data) {
            if (this.state.selectedGroup === 'all') {
                return Object.keys(this.state.data).map((kg) => (
                    this.state.data[kg].filter((k) => (k && k.length > 0)).map((k) => (
                        <Tag
                            interactive
                            key={k}
                            minimal
                            round
                            style={{ margin: 5 }}
                            onClick={(e) => { this.props.addKeyword(k); }}
                            intent={this.state.selectedKeywords.indexOf(k) > -1 ? 'primary' : null}
                        >{k}</Tag>
                    ))
                ));
            } else {
                return (
                    this.state.data[this.state.selectedGroup].filter((k) => (k && k.length > 0)).map((k) => (
                        <Tag
                            interactive
                            key={k}
                            minimal
                            round
                            style={{ margin: 5 }}
                            intent={this.state.selectedKeywords.indexOf(k) > -1 ? 'primary' : null}
                            onClick={(e) => { this.props.addKeyword(k); }}
                        >{k}</Tag>
                    ))
                )
            }
        } else return null;
    }

    setSelectedGroup(group) {
        this.setState({
            selectedGroup: group
        })
    }

    render() {
        return this.state.loaded ? (
            <>
                <Navbar>
                    <Navbar.Group align={Alignment.Left}>
                        <Tabs
                            animate={true}
                            id="tabsKeywords"
                            key="tabsKeywords"
                            selectedTabId={this.state.selectedGroup}
                            onChange={this.setSelectedGroup}
                            large={false}
                            style={{
                                overflow: 'hidden'
                            }}
                        >
                            <Tab id={'all'} title={'All'} />
                            {this.state.data ? (
                                Object.keys(this.state.data).map((kg) => (
                                    <Tab id={kg} title={kg} key={kg} />
                                ))
                            ) : null}
                        </Tabs>
                    </Navbar.Group>
                </Navbar>
                <div style={{ padding: 5, overflow: 'auto', height: 'calc(100% - 50px)' }}>
                    {this.renderKeywords()}
                </div>
            </>
        ) : (this.props.group.group ? <Spinner /> : null)
    }

}

export default KeywordsGroups;