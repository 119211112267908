import React, { Component } from 'react';

import API from '../Utilities/API';

import { ProgressBar, Button, MenuItem, ControlGroup, Tag, Alignment, HTMLSelect } from "@blueprintjs/core";
import { Select, MultiSelect } from "@blueprintjs/select";

import KeywordRequest from '../Keywords/Request';

import DateRangeFilter from '../SavedSearch/DateRangeFilter'

const KeywordMultiSelect = MultiSelect.ofType();
const CountrySelect = Select.ofType();
const LanguageSelect = Select.ofType();

const TypeSelect = Select.ofType();

class Search extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            selected: this.props.selectedKeywords || [],
            loaded: false,
            countries: [],
            languages: [],
            query: this.props.query,
            selectedCountry: this.props.selectedCountry || {
                name: 'All Countries'
            },
            selectedLanguage: this.props.selectedLanguage || {
                name: 'All Languages'
            },
            loading: false,
            lastq: '',
            keywordShow: false,
            type: props.kwtype || 'or',
            range: [null, null]
        }

        this.loadData = this.loadData.bind(this);

        this.renderLanguage = this.renderLanguage.bind(this);
        this.renderCountry = this.renderCountry.bind(this);

        this.renderItem = this.renderItem.bind(this);
        this.renderTag = this.renderTag.bind(this);

        this.filter = this.filter.bind(this);

        this.selectCountry = this.selectCountry.bind(this);
        this.selectLanguage = this.selectLanguage.bind(this);

        this.search = this.search.bind(this);

        this.timer = 0;
        this.TypeAhead = this.TypeAhead.bind(this);
        this.removeSelected = this.removeSelected.bind(this);

        this.duplicate = this.duplicate.bind(this);
        this.setRange = this.setRange.bind(this);
    }

    setRange(range) {
        this.setState({
            range: range
        })
    }

    componentDidMount() {
        if (!this.state.loaded && !this.props.hideCountry && !this.props.hideLanguage) {
            this.loadData();
        } else {
            this.setState({
                loaded: true
            })
        }
    }

    loadData() {
        API.getFilters().then((response) => {
            this.setState({
                loaded: true,
                countries: [
                    {
                        name: 'All Countries'
                    },
                    ...response.data.Data.countries,
                ],
                languages: [
                    {
                        name: 'All Languages'
                    },
                    ...response.data.Data.language
                ],
                type: 'or',
                range: [null, null]
            })
        })
    }
    TypeAhead(q) {
        if (this.state.loading === true) return false;
        this.setState({
            loading: true
        }, () => {
            API.TypeAhead(q).then((response) => {
                let ns = {
                    items: response.data.error ? [] : [...response.data.Data, { request: true }],
                    loading: false,
                    query: ''
                };
                let doSearch = false;
                if (this.state.query && this.state.query.length > 2 && response.data.Data.length) {
                    ns.selected = [response.data.Data[0]];
                    doSearch = true;
                }
                this.setState(ns, () => {
                    //if (doSearch) this.search();
                });
            });
        })
    }

    renderItem(item, { handleClick }) {
        if (item.loading) {
            return <MenuItem
                text={<ProgressBar />}
                shouldDismissPopover={false}
                style={{
                    width: 450
                }}
            />
        }
        else if (item.request) {
            return <MenuItem icon={'plus'} text={'Request creation'} intent={'primary'} onClick={() => {
                this.setState({
                    keywordShow: true
                })
            }} />;
        }

        return (
            <MenuItem
                icon={null}
                key={item.id}
                label={item.calculated_type}
                text={item.title}
                shouldDismissPopover
                onClick={handleClick}
                style={{
                    width: 450
                }}
            />
        );
    }

    renderLanguage(l, { handleClick }) {
        return <MenuItem
            text={l.name}
            onClick={handleClick}
        />
    }

    filter(query, data, i, exact) {
        const title = data.name.toLowerCase();
        query = query.toLowerCase();
        if (exact) return title === query;
        else return title.indexOf(query) > -1;
    }

    renderCountry(c, { handleClick }) {
        return <MenuItem
            text={<div dangerouslySetInnerHTML={{ __html: c.name }}></div>}
            onClick={handleClick}
        />
    }

    removeSelected(id) {
        this.setState({
            selected: this.state.selected.filter((sel) => (sel.id != id))
        })
    }

    renderTag(item) {
        return item.title;
    }

    selectCountry(c) {
        this.setState({
            selectedCountry: c
        })
    }

    selectLanguage(l) {
        this.setState({
            selectedLanguage: l
        })
    }

    search() {
        this.props.search(this.state.selectedCountry, this.state.selectedLanguage, this.state.selected, this.state.lastq, this.state.type, this.state.range);
    }

    duplicate() {
        this.props.addPage();
        this.setState({
            selectedCountry: {
                name: 'Country'
            },
            selectedLanguage: {
                name: 'Language'
            },
            selected: [],
            type: 'or',
            range: [null, null]
        }, () => {
            this.search();
        });
    }

    render() {
        return !this.state.loaded ? <ProgressBar /> : (
            <>
                {this.state.keywordShow && (<KeywordRequest
                    text={this.state.lastq}
                    key={`RequestKeyword-${this.state.lastq}`}
                    onHide={() => {
                        this.setState({
                            keywordShow: false
                        })
                    }}
                />)}
                <ControlGroup style={this.props.style}>
                    {this.props.hideCountry ? null : (
                        <CountrySelect
                            items={this.state.countries}
                            itemPredicate={this.filter}
                            itemRenderer={this.renderCountry}
                            onItemSelect={this.selectCountry}
                            popoverProps={{ className: 'limit-popup' }}
                        >
                            <Button text={this.state.selectedCountry.name.replace(/\&nbsp;/g, '').trim()} rightIcon="double-caret-vertical" />
                        </CountrySelect>
                    )}
                    {this.props.hideLanguage ? null : (
                        <LanguageSelect
                            items={this.state.languages}
                            itemPredicate={this.filter}
                            itemRenderer={this.renderLanguage}
                            onItemSelect={this.selectLanguage}
                            popoverProps={{ className: 'limit-popup' }}
                        >
                            <Button text={this.state.selectedLanguage.name} rightIcon="double-caret-vertical" />
                        </LanguageSelect>
                    )}
                    <KeywordMultiSelect
                        id={'KeywordMultiSelect'}
                        query={this.props.query}
                        fill
                        large
                        hasInitialContent={false}
                        resetOnSelect
                        tagMinimal
                        matchTargetWidth
                        itemRenderer={this.renderItem}
                        tagRenderer={this.renderTag}
                        items={this.state.items}
                        style={{
                            overflow: 'hidden'
                        }}
                        noResults={<>
                            <MenuItem disabled={true} text="No results." />
                            {this.state.lastq.length > 2 && (<MenuItem icon={'plus'} text={'Request creation'} intent={'primary'} onClick={() => {
                                this.setState({
                                    keywordShow: true
                                })
                            }} />)}
                            {this.props.noResultsAddon}
                        </>}
                        selectedItems={this.state.selected}
                        onQueryChange={(q) => {
                            this.props.onChange && this.props.onChange(q);
                            if (q.length > 2) {
                                this.setState({
                                    items: [{ loading: true }],
                                    lastq: q,
                                    query: ''
                                }, () => {
                                    clearTimeout(this.timer);
                                    this.timer = setTimeout(() => {
                                        this.TypeAhead(q);
                                    }, 1000);
                                })
                            } else {
                                this.setState({
                                    items: [],
                                    lastq: q,
                                    query: ''
                                })
                            }
                        }}
                        onItemSelect={(item) => {
                            let selected = this.state.selected;
                            selected.push(item);
                            this.props.onSelectKeyword && this.props.onSelectKeyword(item);
                            this.setState({
                                selected: selected
                            });
                        }}
                        onRemove={(item) => {
                            this.props.onRemoveKeyword && this.props.onRemoveKeyword(item);
                            this.removeSelected(item.id)
                        }}
                    />
                    <HTMLSelect onChange={(e) => {
                        this.setState({
                            type: e.target.value
                        })
                    }} minimal style={{
                        boxShadow: '0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2)',
                        backgroundColor: 'rgba(16, 22, 26, 0.3)'
                    }}>
                        <option value="or" selected={this.state.type === 'or'}>OR</option>
                        <option value="and" selected={this.state.type === 'and'}>AND</option>
                    </HTMLSelect>
                    <DateRangeFilter
                        minimal
                        onChange={(range) => {
                            this.setRange(range);
                        }}
                    />
                    <Button icon="search" text={'Search'} onClick={this.search} />
                    {this.props.saveFunction ? <Button icon={'tick'} onClick={() => {
                        this.props.saveFunction({
                            selectedKeywords: this.state.selected,
                            selectedCountry: this.state.selectedCountry,
                            selectedLanguage: this.state.selectedLanguage
                        });
                        this.search();
                    }} intent={'success'}>Save</Button> : null}
                    {this.props.searchButtons ? this.props.searchButtons : (
                        this.state.selectedCountry.id || this.state.selectedLanguage.id || this.state.selected.length ? (
                            <>
                                <Button icon="remove" intent={'danger'} onClick={() => {
                                    this.setState({
                                        selectedCountry: {
                                            name: 'All Countries'
                                        },
                                        selectedLanguage: {
                                            name: 'All Languages'
                                        },
                                        selected: []
                                    }, () => {
                                        this.props.onReset && this.props.onReset();
                                        this.search();
                                    })
                                }} />
                                {!this.props.hideDuplicate && <Button icon={'duplicate'} intent={'primary'} text={'Duplicate'} onClick={this.duplicate} />}
                            </>
                        ) : null
                    )}
                </ControlGroup>
            </>
        );
    }
}

export default Search;