import React from 'react';

import { Dialog, Button, Tag, Classes, Menu, MenuItem } from '@blueprintjs/core'

import { Popover2 } from '@blueprintjs/popover2';

import API from '../Utilities/API';

class ShareButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            open: false,
            data: [],
            shared: []
        }

        this.loadData = this.loadData.bind(this)
        this.onClose = this.onClose.bind(this);

        this.Save = this.Save.bind(this);

        this.renderShareList = this.renderShareList.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.loadData();
        }
    }

    loadData() {
        API.Sharing(this.props.content_id).then((response) => {
            this.setState({
                data: response.data.Data,
                shared: response.data.Data.data ? response.data.Data.data.map((e) => (e.user_id)) : [],
                loaded: true
            })
        });
    }

    onClose() {
        this.setState({
            open: false
        })
    }

    Save() {
        API.Share(this.props.content_id, this.state.shared).then((response) => {
            this.setState({
                loaded: false,
                open: false
            }, () => {
                this.loadData();
                if (this.props.onShare) this.props.onShare();
            });
        });
    }

    renderShareList() {
        if (!this.state.data || !this.state.data.data || !this.state.data.data.length) return null;
        else {
            return <Menu>
                {this.state.data.data.map((e) => (
                    <MenuItem onClick={() => { this.setState({ open: true }) }} icon={'user'} text={e.user_name} />
                ))}
            </Menu>
        }
    }

    render() {
        return (!this.state.data.data || this.state.data.data.length === 0) && this.props.hideEmpty ? null : (
            <>
                <Popover2 position={'top'} content={this.renderShareList()} interactionKind={'hover'}>
                    <Button intent={this.props.intent} loading={!this.state.loaded} icon={'user'} minimal={this.props.minimal} onClick={() => {
                        this.setState({
                            open: true
                        })
                    }} style={this.props.style}>
                        {this.props.text ? this.props.text : (this.props.text === false ? null : 'Share')}
                        {this.state.loaded && this.state.data && this.state.data.data && this.state.data.data.length > 0 ? (
                            <>
                                &nbsp;
                                <Tag style={{ float: 'right' }}>{this.state.data.data.length}</Tag>
                            </>
                        ) : null}
                    </Button>
                </Popover2>
                {this.state.loaded ? (
                    <Dialog
                        isOpen={this.state.open}
                        onClose={this.onClose}
                        autoFocus={true}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        enforceFocus={true}
                        usePortal={true}
                        className={Classes.DARK}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <p>
                                <div style={{ height: 600, width: '50%', float: 'left', padding: 10 }}>
                                    <h3>Users</h3>
                                    {this.state.data.users.filter((u) => (this.state.shared.indexOf(u.id) === -1)).map((u) => (
                                        <Button intent={this.props.mark_id && this.props.mark_id === u.id ? 'primary' : null} onClick={() => {
                                            let shared = this.state.shared;
                                            shared.push(u.id);
                                            this.setState({
                                                shared: shared
                                            })
                                        }} fill style={{ marginBottom: 10 }}>{u.first_name + ' ' + u.last_name}</Button>
                                    ))}
                                </div>
                                <div style={{ height: 600, width: '50%', float: 'left', padding: 10 }}>
                                    <h3>Shared with</h3>
                                    {this.state.data.users.filter((u) => (this.state.shared.indexOf(u.id) > -1)).map((u) => (
                                        <Button intent={this.props.mark_id && this.props.mark_id === u.id ? 'primary' : null} onClick={() => {
                                            let shared = this.state.shared.filter((id) => (id != u.id));
                                            this.setState({
                                                shared: shared
                                            })
                                        }} fill style={{ marginBottom: 10 }}>{u.first_name + ' ' + u.last_name}</Button>
                                    ))}
                                </div>
                            </p>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button onClick={this.Save} loading={this.state.sending} intent={'primary'} icon={this.props.saveIcon ? this.props.saveIcon : 'user'}>{this.props.saveText ? this.props.saveText : 'Share'}</Button>
                                <Button onClick={this.onClose}>Close</Button>
                            </div>
                        </div>

                    </Dialog>
                ) : null}
            </>
        )
    }
}

export default ShareButton;